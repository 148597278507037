import Vue from "vue";
import Router from "vue-router";

// const Index = () => import("../pages/index.vue");
const Home = () => import("../pages/home.vue");//新版首页
const Contact = () => import("../pages/contact.vue");
// const Join = () => import("../pages/join.vue");
// const Training = () => import("../pages/training.vue");
const TestRegistration = () => import("../pages/testRegistration/index.vue");
const ScoreInquiry = () => import("../pages/scoreInquiry/index.vue");
const TeenagerTestRegistration = () => import("../pages/teenagerTestRegistration/index.vue");
const TestSearch = () => import("../pages/testSearch/index.vue");
const TestUserInfo = () => import("../pages/testSearch/testUserInfo.vue");
const RegSuccess = () => import("../pages/teenagerTestRegistration/success.vue");
const Media = () => import("../pages/media.vue");
const Party = () => import("../pages/party");
const PartyView = () => import("../pages/party/view.vue");
const News = () => import("../pages/news");
const NewsView = () => import("../pages/news/view.vue");
// const Team = () => import("../pages/team.vue");
const User = () => import("../pages/user");
const UserView = () => import("../pages/user/view.vue");
const UserView2 = () => import("../pages/user/view2.vue");
// aboutBand 关于乐团
const Orchestra = () => import("../pages/aboutBand/orchestra/index.vue");
const OrchestraLeader = () => import("../pages/aboutBand/leadership/index.vue");
const Artist = () => import("../pages/aboutBand/artist/index.vue");
const Team = () => import("../pages/aboutBand/managementTeam/index.vue");
// show 乐团演出
const Music = () => import("../pages/show/musicSeason/index.vue");
const Usshow = () => import("../pages/show/america/index.vue");
const Othershow = () => import("../pages/show/template/index.vue");
const Europe = () => import("../pages/show/europe/index.vue");
const Japan = () => import("../pages/show/japan/index.vue");
// orchestraInformation 乐团资讯
const Tx = () => import("../pages/orchestraInformation/groupNews/index.vue");
const BrandActivity = () => import("../pages/orchestraInformation/brand/index.vue");
const ChronicleOfEvents = () => import("../pages/orchestraInformation/record/index.vue");
// artEducation 艺术教育
const Training = () => import("../pages/artEducation/artTraining/index.vue");
// publicWelfare 公益惠民
const PublicBenefit = () => import("../pages/publicWelfare/index.vue");
const PublicView = () => import("../pages/publicWelfare/view.vue");
// contactUs 联系我们
const Join = () => import("../pages/contactUs/joinUs/index.vue");
//
const Concert = () => import("../pages/concert");
const ConcertView = () => import("../pages/concert/view.vue");
const Works = () => import("../pages/works");
const WorksView = () => import("../pages/works/view.vue");
const Consultant = () => import("../pages/consultant.vue");
const League = () => import("../pages/league/indexTwo.vue");
const loanSheetMusic = () => import("../pages/loanSheetMusic.vue");
// const Music = () => import("../pages/music.vue");


Vue.use(Router);

const router = new Router({
  routes: [
    // { path: "/", name: "Index", component: Index }, //首页
    { path: "/", name: "Home", component: Home }, //新版首页
    { path: "/contact", name: "Contact", component: Contact }, //联系我们
    // { path: "/join", name: "Join", component: Join }, //人才招募
    // { path: "/training", name: "Training", component: Training }, //艺教培训
    { path: "/training/testRegistration", name: "TestRegistration", component: TestRegistration }, //考试报名
    { path: "/training/scoreInquiry", name: "ScoreInquiry", component: ScoreInquiry }, //成绩查询
    { path: "/training/teenagerTestRegistration", name: "TeenagerTestRegistration", component: TeenagerTestRegistration }, //青少年民族器乐大赛考试报名
    { path: "/training/testSearch", name: "TestSearch", component: TestSearch }, // 准考证查询 成绩查询
    { path: "/training/testUserInfo", name: "TestUserInfo", component: TestUserInfo }, //准考证查询
    { path: "/training/success", name: "RegSuccess", component: RegSuccess }, // 报名成功
    { path: "/media", name: "Media", component: Media }, //新媒体
    { path: "/party", name: "Party", component: Party }, //党建
    { path: "/party/:id", name: "PartyView", component: PartyView }, //党建详情
    { path: "/news", name: "News", component: News }, //新闻
    { path: "/news/:id", name: "NewsView", component: NewsView }, //党建详情
    // { path: "/team", name: "Team", component: Team }, //团队
    { path: "/user", name: "User", component: User }, //乐手
    { path: "/user1", name: "UserView", component: UserView }, //用户详情
    { path: "/user2", name: "UserView2", component: UserView2 }, //用户详情
    // aboutBand 关于乐团
    { path: "/orchestra", name: "Orchestra", component: Orchestra }, //乐团详情页
    { path: "/orchestraLeader", name: "OrchestraLeader", component: OrchestraLeader }, //领导
    { path: "/artist", name: "Artist", component: Artist }, //艺术家
    { path: "/team", name: "Team", component: Team }, //经营管理团队
    //show 乐团演出
    { path: "/music", name: "Music", component: Music },//音乐季
    { path: "/usshow", name: "Usshow", component: Usshow },//美国巡演
    { path: "/othershow", name: "Othershow", component: Othershow },//巡演模板
    { path: "/europe", name: "Europe", component: Europe },//欧洲巡演
    { path: "/japan", name: "Japan", component: Japan },//日本巡演
    // orchestraInformation 乐团资讯
    { path: "/tx", name: "Tx", component: Tx }, //团讯
    { path: "/brandActivity", name: "BrandActivity", component: BrandActivity },//品牌活动
    { path: "/chronicleOfEvents", name: "ChronicleOfEvents", component: ChronicleOfEvents },//大事记
    //artEducation 艺术教育
    { path: "/training", name: "Training", component: Training }, //艺术培训
    // publicWelfare 公益惠民
    { path: "/publicBenefit", name: "PublicBenefit", component: PublicBenefit },//公益惠民
    { path: "/publicView/:id", name: "PublicView", component: PublicView },//公益惠民详情
    // contactUs 联系我们
    { path: "/join", name: "Join", component: Join },//加入我们
    //
    { path: "/concert", name: "Concert", component: Concert }, //全部演出
    { path: "/concert/:id", name: "ConcertView", component: ConcertView }, //演出详情
    { path: "/works", name: "Works", component: Works }, //委约作品
    { path: "/works/:id", name: "WorksView", component: WorksView }, //委约作品详情
    { path: "/consultant", name: "Consultant", component: Consultant }, //艺术顾问
    // { path: "/music", name: "Music", component: Music }, //音乐季
    { path: "/league", name: "League", component: League }, //联合会
    { path: "/loanSheetMusic", name: "loanSheetMusic", component: loanSheetMusic }, // 租借乐谱
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})
export default router

